/* eslint-disable dot-notation */
import axios from "axios";
import moment from "moment";
import CryptoJS from "crypto-js";
import {
  baseURL,
  STUDYSEARCH,
  STUDYLIST,
  PINNEDSTUDY,
  UNPINSTUDY,
  PINSTUDY,
  HARDDELETE,
  SYNCNOW,
  VLCDATAAPI,
  DATAFLOWSEARCH,
  DATAFLOW_SOURCE,
  DATAFLOW_DETAILS,
  DATAFLOW_SAVE,
  LOCATIONAPI,
  DATAKINDAPI,
  DATAFLOW_UPDATE_API,
  ADD_PACKAGE,
  API_URL,
  INGESTION_ISSUE_URL,
  INGESTION_ISSUE_COL_URL,
  INGESTION_ISSUE_DOWNLOADFILENAME_URL,
  DATASETS_STATUS_TOGGLE,
  DELETE_PACKAGE,
  UPDATE_PACKAGE,
  LISTTYPES,
  INGESTION_ISSUE_COL_DOWNLOAD_URL,
  RESET_DRAFT_DATAFLOW,
  CANCEL_DATAFLOW_PUBLISH,
  PUBLISH_DRAFT_DATAFLOW_FETCH,
  CREATE_DATAFLOW_DRAFT,
  ACTIVATEDF_SDF,
  INACTIVATE_SDF,
  DRAFTDATAFLOW_SAVE,
  ADD_PACKAGE_DRAFT,
  UPDATE_PACKAGE_DRAFT,
  DATASETS_STATUS_TOGGLE_DRAFT,
  DELETE_PACKAGE_DRAFT,
  SAVEDATAFLOW_CLONE_DATAFLOW,
  DATAFLOW_DRAFT_UPDATE_API,
  DRAFTCOLUMNSAPI,
  DOWNLOAD_TRANSFER_LOG_FILE,
  AMAZON_REGIONS,
  SUMMARY_REPORT_URL,
  INGESTIONREPORTAPI,
  LOCATION_LOV,
  ODMDRAFTDATAFLOW_SAVE,
  DELETE_DRAFT_DATAFLOW,
  POST_REJECT_RULES_STATUS,
  ADD_BDC_PACKAGE_DRAFT,
  GET_LOCATION_GROUP_LIST,
} from "../constants";
import store from "../store";
import { freezeDfVersion } from "../store/actions/DataFlowAction";
import {
  columnsCreated,
  columnsCreatedFailure,
} from "../store/actions/DataSetsAction";
import {
  deleteAllCookies,
  getCookie,
  getTenantId,
  getUserId,
} from "../utils/index";
import { encrypt as encryptPassword } from "../utils/encryptionHelper";
import {
  STANDARDIZE_METADATA,
  STANDARDIZE_METADATA_VALUE,
  TABULAR_VALUE,
} from "../pages/Dashboard/MonitorTab/helper";

// messages
const commonError = "Something went wrong";
const requestTimeOut =
  "Location Connection Failure. Please check with product support.";

const userId = getUserId();

const token = getCookie("user.token");
const tenantName = getTenantId();

const encrypt = (key) => {
  if (!key || !process.env.REACT_APP_ENCRYPTION_KEY)
    return "Encryption key not found";

  // Sample: to be used in case of passing Iv
  // return CryptoJS.AES.encrypt(
  //   CryptoJS.enc.Utf8.parse(key),
  //   CryptoJS.enc.Utf8.parse(process.env.REACT_APP_ENCRYPTION_KEY),
  //       { iv: CryptoJS.enc.Utf8.parse("aaa") }
  // ).toString();

  return CryptoJS.AES.encrypt(
    key,
    process.env.REACT_APP_ENCRYPTION_KEY
  ).toString();
};

axios.defaults.headers.common["api-key"] = encrypt(
  process.env.REACT_APP_API_KEY
);
axios.defaults.headers.common["sys-name"] = process.env.REACT_APP_SYS_NAME;
axios.defaults.headers.common["token-type"] = "user";
axios.defaults.headers.common["access-token"] = encrypt(userId);
axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

const responseHandler = (response) => {
  if (response?.data?.data?.versionBumped) {
    store.dispatch(freezeDfVersion());
  }
  return response;
};
const errorHandler = (error) => {
  return Promise.reject(error);
};
axios.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error)
);

export const checkLocationExistsInDataFlow = async (locId) => {
  try {
    const res = await axios.get(
      `${baseURL}/${LOCATIONAPI}/check-in-df/${locId}`
    );
    return res.data?.data || 0;
  } catch (err) {
    return console.log("Error", err);
  }
};

export const createColumns = async (payload) => {
  try {
    return new Promise((resolve) => {
      axios
        .post(`${baseURL}/${DRAFTCOLUMNSAPI}/create`, payload)
        .then((res) => {
          columnsCreated({ ...res.data, nQuery: payload.nQuery });
          resolve(res.data);
        })
        .catch((err) => {
          if (err.response) {
            columnsCreatedFailure(err.response?.data);
            resolve(err.response.data);
          }
        });
    });
  } catch (err) {
    console.log("Error", err);
    return err?.response?.data || commonError;
  }
};

export const statusUpdate = async (id, status) => {
  try {
    return new Promise((resolve) => {
      axios
        .post(`${baseURL}/${LOCATIONAPI}/statusUpdate`, {
          id,
          status,
          userId,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          console.log("Err", err);
          if (err.response) {
            resolve(err.response.data);
          }
        });
    });
  } catch (err) {
    return console.log("Error", err);
  }
};

const searchStudy = async (searchQuery = "") => {
  try {
    const res = await axios.post(`${baseURL}/${STUDYSEARCH}`, {
      userId,
      searchQuery,
    });
    return res.data?.data || [];
  } catch (err) {
    return console.log("Error", err);
  }
};

export const searchDataflows = async (searchQuery = "", studyId) => {
  try {
    const res = await axios.post(`${baseURL}/${DATAFLOWSEARCH}`, {
      userId,
      studyId,
      searchQuery,
    });
    return res.data?.data || [];
  } catch (err) {
    return console.log("Error", err);
  }
};

export const getDataFlowDetails = async (dataflowId) => {
  try {
    const res = await axios.get(`${baseURL}/${DATAFLOW_DETAILS}/${dataflowId}`);
    return res.data?.data || [];
  } catch (err) {
    return console.log("Error", err);
  }
};

export const fetchDataFlowSource = async (dataflowId) => {
  try {
    const res = await axios.get(`${baseURL}/${DATAFLOW_SOURCE}/${dataflowId}`);
    return res.data?.data || [];
  } catch (err) {
    return console.log("Error", err);
  }
};

export const checkConnection = async (reqBody, isVeeva = false) => {
  try {
    const { endPoint, ...params } = reqBody;
    // encrypt password before sending
    if (params?.password) {
      const encryptedPassword = encryptPassword(params.password);
      params.password = encryptedPassword || params?.password;
    }
    if (params?.ppk) {
      const encryptedPPK = encryptPassword(params.ppk);
      params.ppk = encryptedPPK || params?.ppk;
    }
    // encrypt secret key - S3 before sending
    if (params?.secretAccessKey) {
      const encryptedPassword = encryptPassword(params.secretAccessKey);
      params.secretAccessKey = encryptedPassword || params?.secretAccessKey;
    }
    const res = await axios.post(
      `${baseURL}${
        isVeeva ? "/v1/api/test-connect-publish" : "/v1/api/test-connect"
      }`,
      {
        params,
        endPoint,
      }
    );
    return res.data || [];
  } catch (err) {
    if (err?.response.status === 408) {
      return { message: requestTimeOut, status: 408 };
    }
    return {
      message: err?.response?.data?.message || commonError,
      status: err?.response?.status,
    };
  }
};

export const dataflowSave = async (payload) => {
  try {
    const res = await axios.post(`${baseURL}/${DATAFLOW_SAVE}`, {
      ...payload,
      userId,
    });
    return res.data?.data || [];
  } catch (error) {
    return {
      success: false,
      data: error?.response?.data,
      status: error?.response?.status,
    };
  }
};

export const saveDraftDataFlow = async (payload) => {
  try {
    const res = await axios.post(`${baseURL}/${DRAFTDATAFLOW_SAVE}`, {
      ...payload,
      userId,
    });
    return res.data?.data || [];
  } catch (error) {
    return {
      success: false,
      data: error?.response?.data,
      status: error?.response?.status,
    };
  }
};

export const saveODMDraftDataFlow = async (payload) => {
  try {
    const res = await axios.post(`${baseURL}/${ODMDRAFTDATAFLOW_SAVE}`, {
      ...payload,
      userId,
    });
    return res.data?.data || [];
  } catch (error) {
    return {
      success: false,
      data: error?.response?.data,
      status: error?.response?.status,
    };
  }
};

export const updateDataflow = async (payload) => {
  try {
    const res = await axios.post(`${baseURL}/${DATAFLOW_UPDATE_API}`, {
      ...payload,
      userId,
    });
    return res.data || [];
  } catch (err) {
    console.log("Error", err);
    return err?.response?.data;
  }
};

export const hardDelete = async (
  dataFlowId,
  dataFlowName,
  version,
  studyId,
  fsrStatus
) => {
  try {
    const res = await axios.post(`${baseURL}/${HARDDELETE}`, {
      dataFlowId,
      dataFlowName,
      version,
      studyId,
      fsrStatus,
      userId,
    });
    return res.data?.data || [];
  } catch (err) {
    return console.log("Error", err);
  }
};

export const getVLCDataList = async () => {
  try {
    const res = await axios.post(`${baseURL}/${VLCDATAAPI}`, {});
    return res.data?.data || [];
  } catch (err) {
    return console.log("Error", err);
  }
};

export const syncNowDataFlow = async ({
  version,
  dataFlowId,
  syncRCDS = false,
  syncRave = false,
}) => {
  try {
    const currentTime = moment().utc().format("YYYY-MM-DD HH:mm:ss");
    const res = await axios.post(`${baseURL}/${SYNCNOW}`, {
      version,
      userId,
      dataFlowId,
      action: "SYNC",
      currentTime,
      syncRCDS,
      syncRave,
    });
    return res?.data || [];
  } catch (err) {
    return console.log("Error", err);
  }
};

export const activateDF = async (dataFlowId, versionFreezed = false) => {
  try {
    const res = await axios.post(`${baseURL}/${ACTIVATEDF_SDF}`, {
      dataFlowId,
      userId,
      versionFreezed,
    });
    return res?.data || [];
  } catch (err) {
    return err.response?.data || {};
  }
};

export const inActivateDF = async (dataFlowId, versionFreezed = false) => {
  try {
    const res = await axios.post(`${baseURL}/${INACTIVATE_SDF}`, {
      dataFlowId,
      userId,
      versionFreezed,
    });
    return res.data || [];
  } catch (err) {
    return err.response?.data || {};
  }
};

export const activateDK = async (dkId, dkStatus) => {
  try {
    const res = await axios.post(`${baseURL}/${DATAKINDAPI}/status-update`, {
      dkId,
      dkStatus,
    });
    return res.data?.data || [];
  } catch (err) {
    return console.log("Error", err);
  }
};

export const inActivateDK = async (dkId, dkStatus) => {
  try {
    return new Promise((resolve) => {
      axios
        .post(`${baseURL}/${DATAKINDAPI}/status-update`, {
          dkId,
          dkStatus,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          if (err.response) {
            resolve(err.response.data);
          }
        });
    });
  } catch (err) {
    return console.log("Error", err);
  }
};

export const addDK = async (reqBody) => {
  try {
    return new Promise((resolve) => {
      axios
        .post(`${baseURL}/${DATAKINDAPI}/create`, reqBody)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          if (err.response) {
            resolve(err.response.data);
          }
        });
    });
  } catch (err) {
    return console.log("Error", err);
  }
};

export const updateDK = async (reqBody) => {
  try {
    return new Promise((resolve) => {
      axios
        .post(`${baseURL}/${DATAKINDAPI}/create`, reqBody)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          if (err.response) {
            resolve(err.response.data);
          }
        });
    });
  } catch (err) {
    return console.log("Error", err);
  }
};

export const getENSList = async () => {
  try {
    const res = await axios.get(`${baseURL}/${DATAKINDAPI}/ens/list`);
    return res.data?.data || [];
  } catch (err) {
    return console.log("Error", err);
  }
};

export const pinStudy = async (protocolId) => {
  try {
    const res = await axios.post(`${baseURL}/${PINSTUDY}`, {
      userId,
      protocolId,
    });
    return res.data?.data || [];
  } catch (err) {
    return console.log("Error", err);
  }
};

export const unPinStudy = async (protocolId) => {
  try {
    const res = await axios.post(`${baseURL}/${UNPINSTUDY}`, {
      userId,
      protocolId,
    });
    return res.data?.data || [];
  } catch (err) {
    return console.log("Error", err);
  }
};

export const getStudies = async () => {
  try {
    const res = await axios.get(`${baseURL}/${STUDYLIST}/${userId}`);
    return res.data?.data || [];
  } catch (err) {
    return console.log("Error", err);
  }
};

export const getPinnedStudies = async () => {
  try {
    const res = await axios.get(`${baseURL}/${PINNEDSTUDY}/${userId}`);
    return res.data?.data || [];
  } catch (err) {
    return console.log("Error", err);
  }
};

export const userLogOut = () => {
  return axios
    .get(`${baseURL}/logout`)
    .then(async (res) => {
      if (res.data) {
        const deleted = await deleteAllCookies();
        return deleted;
      }
      return false;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const deleteCD = async (columnId, dsId, dpId, dfId, CDVersionBump) => {
  try {
    const res = await axios.post(`${baseURL}/${DRAFTCOLUMNSAPI}/delete`, {
      columnId,
      dsId,
      dfId,
      dpId,
      userId,
      versionFreezed: CDVersionBump,
    });
    return res.data?.status === 1;
  } catch (err) {
    if (err.response) {
      return err?.response?.data || false;
    }
    return console.log("Error", err);
  }
};

export const submitDataPackage = async (reqBody) => {
  try {
    const res = await axios.post(`${baseURL}/${ADD_PACKAGE}`, reqBody);
    return res.data || [];
  } catch (err) {
    return console.log("Error", err);
  }
};

export const getRolesPermissions = async (studyId) => {
  try {
    const res = await axios.post(`${API_URL}/role/getUserRolesPermissions`, {
      productName: "Ingestion",
      studyId,
    });
    return res.data?.data || res.data;
  } catch (err) {
    if (err.response) return err.response;
    return { message: commonError };
  }
};

export const getIngestionIssues = async (datasetId, externalid) => {
  try {
    const res = await axios.get(
      `${baseURL}/${INGESTION_ISSUE_URL}/${datasetId}${
        externalid ? `/${externalid}` : ""
      }`
    );
    return res.data?.data || [];
  } catch (err) {
    return console.log("Error", err);
  }
};

export const getColumnDetails = async (datasetid, externalid, columns) => {
  try {
    const res = await axios.get(
      `${baseURL}/v1/api/dataset/ingestion-report/getColumnDetails/${datasetid}/${externalid}/${columns}`
    );
    return res.data?.data || [];
  } catch (err) {
    return console.log("Error", err);
  }
};
// eslint-disable-next-line consistent-return
export const getIngestionIssueCols = async (reqBody) => {
  try {
    const res = await axios.post(
      `${baseURL}/${INGESTION_ISSUE_COL_URL}`,
      reqBody
    );
    return res.data || [];
  } catch (err) {
    return {
      data: [],
      error: err.response?.data?.message || commonError,
    };
  }
};

export const getdownloadfileName = async (datasetid) => {
  try {
    const res = await axios.get(
      `${baseURL}/${INGESTION_ISSUE_DOWNLOADFILENAME_URL}/${datasetid}`
    );
    console.log(res.data);
    return res.data || [];
  } catch (err) {
    return {
      data: [],
      error: err.response?.data?.message || commonError,
    };
  }
};

// eslint-disable-next-line consistent-return
export const downloadIngestionIssueCols = async (reqBody) => {
  try {
    const res = await axios.post(
      `${baseURL}/${INGESTION_ISSUE_COL_DOWNLOAD_URL}`,
      reqBody
    );
    return res.data || [];
  } catch (err) {
    return {
      data: [],
      error: err.response?.data?.message || commonError,
    };
  }
};

export const toggleDatasetsStatus = async (reqBody) => {
  try {
    const res = await axios.post(
      `${baseURL}/${DATASETS_STATUS_TOGGLE}`,
      reqBody
    );
    return res.data || [];
  } catch (err) {
    return {
      data: [],
      error: err.response?.data?.message || commonError,
    };
  }
};

export const deletePackage = async (reqBody) => {
  try {
    const res = await axios.post(`${baseURL}/${DELETE_PACKAGE}`, reqBody);
    return res.data || [];
  } catch (err) {
    return {
      data: [],
      error: err.response?.data?.message || commonError,
    };
  }
};

export const updatePackageStatus = async (reqBody) => {
  try {
    const res = await axios.post(`${baseURL}/${UPDATE_PACKAGE}`, reqBody);
    return res.data || [];
  } catch (err) {
    return {
      data: [],
      error: err.response?.data?.message || commonError,
    };
  }
};

export const getListTypes = async (reqBody) => {
  try {
    const res = await axios.get(`${baseURL}/${LISTTYPES}`, reqBody);
    const result = res.data || {};
    return result;
  } catch (err) {
    return {
      data: [],
      error: err.response?.data?.message || commonError,
    };
  }
};

export const getListOfValues = async (reqBody) => {
  try {
    const res = await axios.get(`${baseURL}/${LOCATION_LOV}`, reqBody);
    const result = res?.data?.data || {};
    return result;
  } catch (err) {
    return {
      data: [],
      error: err.response?.data?.message || commonError,
    };
  }
};

export const getGroupList = async (reqBody) => {
  try {
    const res = await axios.get(
      `${baseURL}/${GET_LOCATION_GROUP_LIST}`,
      reqBody
    );
    const result = res?.data?.data || {};
    return result;
  } catch (err) {
    return {
      data: [],
      error: err.response?.data?.message || commonError,
    };
  }
};

export const fetchRegions = async () => {
  try {
    const res = await axios.get(`${baseURL}/${AMAZON_REGIONS}`);
    const result = res.data || {};
    return result;
  } catch (err) {
    return {
      data: [],
      error: err.response?.data?.message || commonError,
    };
  }
};

export const resetDraftDataflow = async (dataFlowId) => {
  try {
    const res = await axios.post(`${baseURL}/${RESET_DRAFT_DATAFLOW}`, {
      dataFlowId,
    });
    return res.data || [];
  } catch (err) {
    return {
      error: err.response?.data?.message || commonError,
    };
  }
};

export const deleteDraftDataflow = async (dataFlowId) => {
  try {
    const res = await axios.post(`${baseURL}/${DELETE_DRAFT_DATAFLOW}`, {
      dataFlowId,
    });
    return res.data || [];
  } catch (err) {
    return {
      error: err.response?.data?.message || commonError,
    };
  }
};

export const cancelDataflowPublish = async (dataFlowId) => {
  try {
    const res = await axios.post(`${baseURL}/${CANCEL_DATAFLOW_PUBLISH}`, {
      dataFlowId,
    });
    return res.data || [];
  } catch (err) {
    return {
      error: err.response?.data?.message || commonError,
    };
  }
};

export const retryDataflowPublish = async (reqBody) => {
  try {
    const res = await axios.post(
      `${baseURL}/${PUBLISH_DRAFT_DATAFLOW_FETCH}`,
      reqBody
    );
    return res.data || [];
  } catch (err) {
    return {
      error: err.response?.data?.message || commonError,
    };
  }
};

export const createDataflowDraft = async (dataFlowId) => {
  try {
    const res = await axios.post(`${baseURL}/${CREATE_DATAFLOW_DRAFT}`, {
      dataFlowId,
      userId,
    });
    return res.data || [];
  } catch (err) {
    return {
      error: err.response?.data?.message || commonError,
    };
  }
};

export const saveDraftDataPackage = async (reqBody) => {
  try {
    const res = await axios.post(`${baseURL}/${ADD_PACKAGE_DRAFT}`, reqBody);
    return res.data || [];
  } catch (err) {
    return {
      data: [],
      error: err.response?.data?.message || commonError,
    };
  }
};

export const updateDraftPackageStatus = async (reqBody) => {
  try {
    const res = await axios.post(`${baseURL}/${UPDATE_PACKAGE_DRAFT}`, reqBody);
    return res.data || [];
  } catch (err) {
    return {
      data: [],
      error: err.response?.data?.message || commonError,
    };
  }
};
export const saveBDCDraftDataPackage = async (reqBody) => {
  const url = `${baseURL}/${ADD_BDC_PACKAGE_DRAFT}`;

  try {
    const res = await axios.post(url, reqBody);
    return res.data || [];
  } catch (err) {
    return {
      data: [],
      error: err.response?.data?.message || commonError,
    };
  }
};

export const toggleDraftDatasetsStatus = async (reqBody) => {
  try {
    const res = await axios.post(
      `${baseURL}/${DATASETS_STATUS_TOGGLE_DRAFT}`,
      reqBody
    );
    return res.data || [];
  } catch (err) {
    return {
      data: [],
      error: err.response?.data?.message || commonError,
    };
  }
};

export const deleteDraftPackage = async (reqBody) => {
  try {
    const res = await axios.post(`${baseURL}/${DELETE_PACKAGE_DRAFT}`, reqBody);
    return res.data || [];
  } catch (err) {
    return {
      data: [],
      error: err.response?.data?.message || commonError,
    };
  }
};

export const cloneDataFlow = async (reqObject) => {
  try {
    const res = await axios.post(`${baseURL}/${SAVEDATAFLOW_CLONE_DATAFLOW}`, {
      ...reqObject,
      userId,
    });
    return res.data || [];
  } catch (err) {
    return {
      data: [],
      error: err.response?.data?.message || commonError,
    };
  }
};

export const updateDraftDataflow = async (payload) => {
  try {
    const res = await axios.post(`${baseURL}/${DATAFLOW_DRAFT_UPDATE_API}`, {
      ...payload,
      userId,
    });
    return res.data || [];
  } catch (err) {
    console.log("Error", err);
    return err?.response?.data || commonError;
  }
};

function blobToString(b) {
  try {
    const u = URL.createObjectURL(b);
    const x = new XMLHttpRequest();
    x.open("GET", u, false); // although sync, you're not fetching over internet
    x.send();
    URL.revokeObjectURL(u);
    return x.responseText;
  } catch (e) {
    return false;
  }
}

export const downloadTransferLogFile = async (
  fpath,
  fname,
  dataflowId,
  packageId,
  packageName,
  targetDb,
  dataStructure
) => {
  try {
    const res = await axios.post(
      `${baseURL}/${DOWNLOAD_TRANSFER_LOG_FILE}fpath=${encodeURIComponent(
        fpath
      )}&fname=${encodeURIComponent(fname)}`,
      {
        userId,
        dataflow_id: dataflowId,
        package_id: packageId,
        target_db: targetDb,
        data_Structure: dataStructure,
      },
      {
        responseType: "blob",
      }
    );
    const url = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement("a");
    link.href = url;
    if (typeof window.navigator.msSaveBlob === "function") {
      window.navigator.msSaveBlob(res.data, fname || packageName);
    } else {
      link.setAttribute("download", fname || packageName);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    }
    return res.data || [];
  } catch (err) {
    console.log("Error", err);
    return JSON.parse(blobToString(err?.response?.data)) || commonError;
  }
};

export const getSummaryReportData = async (reqBody) => {
  try {
    const res = await axios.post(`${baseURL}/${SUMMARY_REPORT_URL}`, reqBody);
    return res.data || [];
  } catch (err) {
    return {
      data: [],
      error: err.response?.data?.message || commonError,
    };
  }
};

export const downloadTransferLogData = async (
  datasetId,
  start,
  end,
  isDownload = false,
  pipelineType = TABULAR_VALUE
) => {
  try {
    let endDate = "";
    if (end && start) {
      endDate = `&endDate=${end}`;
    } else if (end && !start) {
      endDate = `?endDate=${end}`;
    } else {
      endDate = "";
    }

    let pipeline;
    if (pipelineType === STANDARDIZE_METADATA_VALUE) {
      pipeline = STANDARDIZE_METADATA;
    }

    const res = await axios.get(
      `${baseURL}/${INGESTIONREPORTAPI}/transferlog/${datasetId}${
        // eslint-disable-next-line prefer-template
        start ? "?startDate=" + start : ""
      }${endDate}${
        // eslint-disable-next-line prefer-template
        isDownload ? "&isDownload=Y" : ""
      }&pipelineType=${pipeline}`
    );
    return res.data || [];
  } catch (err) {
    return {
      data: [],
      error: err.response?.data?.message || commonError,
    };
  }
};

export const getCommonAppSwitcher = async () => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_CORE_API_URL}/v1/api/webpage/server`,
      {
        fileName: "app-switcher",
        userId,
        productName: "Admin",
      },
      {
        headers: {
          tenant: tenantName,
        },
      }
    );
    return res.data || null;
  } catch (err) {
    return {
      error: true,
    };
  }
};

export const getTransferViewLogData = async (reqBody) => {
  try {
    const res = await axios.post(
      `${baseURL}/${INGESTIONREPORTAPI}/transferlog/log-summary-data`,
      reqBody
    );
    return res?.data?.data || [];
  } catch (err) {
    return {
      error: err.response?.data?.message || commonError,
    };
  }
};

export const getCumulativeTransferLog = async (datasetid) => {
  try {
    const url = `${baseURL}/${INGESTIONREPORTAPI}/cumulativetransfer/${datasetid}`;
    const res = await axios.get(url);
    return res?.data?.data || [];
  } catch (err) {
    return {
      error: err.response?.data?.message || commonError,
    };
  }
};

export const inActiveRejectRules = async (reqBody) => {
  try {
    const res = await axios.post(
      `${baseURL}/${POST_REJECT_RULES_STATUS}`,
      reqBody
    );
    return res?.data;
  } catch (err) {
    return {
      error: err.response?.data?.message || commonError,
    };
  }
};

export default searchStudy;
